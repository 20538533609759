import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import useStructures from "../../hooks/useStructures/useStructures";
import { useAppSelector } from "../../store/hooks";
import DetailStyled from "./DetailStyled";
import { StructureStructure } from "../../types";

interface DetailProps {
  structure: StructureStructure;
}

const Detail = ({
  structure: { categories, ele, image, ubication, name, desc, id, time },
}: DetailProps): JSX.Element => {
  const { deleteStructure } = useStructures();

  const {
    user: { isLogged },
  } = useAppSelector((state) => state);

  return (
    <>
      {id && (
        <DetailStyled>
          <h2 className="detail__title">{name}</h2>
          <link rel="preload" href={image as string} as="image" />
          <img
            className={image ? "detail__image" : "detail__image--fill"}
            width={268}
            height={200}
            alt={`the views of ${name}`}
            src={(image as string) || "../images/no-photo.jpg"}
          />
          <ul className="detail__info">
            <li>
              <span>Type: {categories.toString()}</span>
            </li>
            <li>
              <span>Elevation: {ele ?? " - "}m</span>
            </li>
            <li>
              <span>Location: {ubication}</span>
            </li>
            <li>
              <span>Creation time: {time}</span>
            </li>
          </ul>
          <div className="wrap-collabsible">
            <input id="collapsible" className="toggle" type="checkbox" />
            <label htmlFor="collapsible" className="lbl-toggle">
              Description
            </label>
            <div className="collapsible-content">
              <div className="content-inner">
                <p>{desc}</p>
              </div>
            </div>
          </div>
          {isLogged && (
            <span className="detail__buttons">
              <NavLink aria-label="modify" to={`structure/edit/${name}/${id}`}>
                <FontAwesomeIcon name="elevation" icon={solid("edit")} />
              </NavLink>

              <button aria-label="delete" onClick={() => deleteStructure(id)}>
                <NavLink to={"/home"}></NavLink>
                <FontAwesomeIcon name="elevation" icon={solid("trash")} />
              </button>
            </span>
          )}
        </DetailStyled>
      )}
    </>
  );
};

export default Detail;
