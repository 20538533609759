import styled from "styled-components";

const LayoutStyled = styled.div`
  display: block;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 71px;
    background-color: lightblue;
    padding: ${(props) => props.theme.padding.mainMobilePadding};
    background-color: ${(props) => props.theme.colour.main};
    display: flex;
    align-items: center;

    &__logo,
    &__sublogo {
      display: flex;
      color: white;
      user-select: none;
      // border-bottom: 2px solid #ccc;
    }

    &__logo {
      align-self: stretch;
      align-items: center;
      font-size: clamp(3rem, 9vw, 4rem);
    }

    &__sublogo {
      align-items: baseline;
      font-size: clamp(1rem, 4.5vw, 2rem);
    }
  }

  .navbar {
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
  }

  .Toastify__close-button > svg {
    min-width: 42px;
    min-height: 42px;
  }

  .content {
    padding: ${(props) => props.theme.padding.mainMobilePadding};
    padding-top: 115px;
  }
  .not-found {
  }
`;

export default LayoutStyled;
