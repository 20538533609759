import React, { useState } from "react";
import FilterStyled from "./FilterStyled";

interface FilterProps {
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
}

const Filter: React.FC<FilterProps> = ({ value, onChange }): JSX.Element => {
  const [mainCategory, setMainCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");

  const handleMainCategoryChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMainCategory(event.target.value);
    await onChange(event);
    setSubCategory("");
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSubCategory(event.target.value);
    onChange(event);
  };

  const renderSubCategoryOptions = () => {
    switch (mainCategory) {
      case "aigua":
        return (
          <>
            <option value="aljubs i pous">Aljubs i pous</option>
            <option value="altres">Altres</option>
            <option value="basses">Basses</option>
            <option value="cisternes">Cisternes</option>
            <option value="fonts">Fonts</option>
          </>
        );
      case "construccions":
        return (
          <>
            <option value="arnere i calatxos">Arnere i calatxos</option>
            <option value="balmes i canyons">Balmes i canyons</option>
            <option value="camins i carrerades">Camins i carrerades</option>
            <option value="conjunts agricoles">Conjunts agricoles</option>
            <option value="edificis">Edificis</option>
            <option value="forns">Forns</option>
            <option value="massos">Massos</option>
          </>
        );
      case "territori":
        return (
          <>
            <option value="bosc i arbres">Bosc i arbres</option>
            <option value="cims colls">Cims colls</option>
            <option value="llocs d'intères">Llocs d'interès</option>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <FilterStyled
        as="select"
        onChange={handleMainCategoryChange}
        value={mainCategory}
        className="form__field"
        id="mainCategory"
        name="categories"
        required
      >
        <option value="">Tots</option>
        <option value="aigua">Aigua</option>
        <option value="construccions">Construccions</option>
        <option value="territori">Territori</option>
      </FilterStyled>

      {mainCategory && (
        <FilterStyled
          as="select"
          onChange={handleSubCategoryChange}
          value={subCategory}
          className="form__field"
          id="subCategory"
          name="subcategories"
          placeholder="subcategoria"
          required
        >
          <option value="">Tots</option>
          {renderSubCategoryOptions()}
        </FilterStyled>
      )}
    </>
  );
};

export default Filter;
