import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle` *,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --toastify-font-family: "Roboto";
}

ul,
li {
  list-style: none;
  padding-inline-start: 0px;
  padding-left: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Roboto", "Basier circle", Geneva, Tahoma, sans-serif;
}

a,
a:focus,
a:active,
a:visited {
  color: inherit;
  text-decoration: none;
}

h2 {
  margin: 0;
  font-weight: inherit;
  margin-block-start: 0;
  margin-block-end: 0;
}

button,
input {
  font-family: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
  color: inherit;
  background: none;
}

.odibee-sans-regular {
  font-family: "Odibee Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.mansalva-regular {
  font-family: "Mansalva", sans-serif;
  font-weight: 400;
  font-style: normal;
}

`;

export default GlobalStyles;
