import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Filter from "../../components/Filter/Filter";
import Structure from "../../components/Structure/Structure";
import useStructures from "../../hooks/useStructures/useStructures";
import { turnOnLoaderActionCreator } from "../../store/features/ui/uiSlice/uiSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import StructuresStyled from "./StructuresPageStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const StructuresPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    structures: { structures, currentPage, totalStructures },
    ui: { isLoading },
  } = useAppSelector((state) => state);

  const { getStructures } = useStructures();

  const filterAndLoadInitialState = {
    loadPage: "1",
    limit: 5,
    categories: "",
    loadMore: false,
  };

  const [filterAndLoad, setFilterAndLoad] = useState(filterAndLoadInitialState);
  const [usingGeolocation, setGeolocation] = useState<boolean>(
    !process.env.REACT_APP_TEST
  );

  let params;
  let crd = { accuracy: 1000, longitude: null, latitude: null };
  useEffect(() => {
    const options = {
      maximumAge: 0,
      timeout: 5000,
      enableHighAccuracy: true,
    };

    if (usingGeolocation && !process.env.REACT_APP_TEST) {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      fetchStructures(crd);
    }

    function success(pos: { coords: any }) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      crd = pos.coords;
      fetchStructures(crd);
    }

    function error(err: { code: any; message: any }) {
      throw new Error(err.message, { cause: err.code });
    }
  }, [dispatch, getStructures, filterAndLoad, usingGeolocation]);

  const fetchStructures = (crd: {
    longitude: number | null;
    accuracy: number;
    latitude: number | null;
  }) => {
    params = new URLSearchParams({
      page: `${filterAndLoad.loadPage}`,
      limit: `${filterAndLoad.limit}`,
      categories: `${filterAndLoad.categories}`,
      coords: `${crd.latitude ? [crd.longitude, crd.latitude] : ""}`,
    });
    getStructures(params, filterAndLoad.loadMore);

    dispatch(turnOnLoaderActionCreator());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterAndLoad({
      ...filterAndLoadInitialState,
      categories: event.target.value,
    });
  };

  return (
    <StructuresStyled>
      <div className="structures__info">
        <div>
          {structures.length}/{totalStructures}
        </div>
        <Filter onChange={handleInputChange} value={filterAndLoad.categories} />
        <div>
          <button onClick={() => setGeolocation(!usingGeolocation)}>
            {usingGeolocation ? (
              <FontAwesomeIcon icon={solid("location-arrow")} />
            ) : (
              <FontAwesomeIcon icon={solid("minus")} />
            )}
          </button>
        </div>
      </div>
      {structures[0] && (
        <ul className="structures">
          {structures.map((structure) => (
            <li key={structure.id}>
              <Structure structure={structure} />
            </li>
          ))}
        </ul>
      )}
      {!isLoading && !structures[0] && (
        <span className="structures__notFound">No structures found...</span>
      )}
      {totalStructures !== structures.length && (
        <div className="structures__load-more">
          <Button
            text="Load more"
            onClick={() => {
              filterAndLoad.loadMore = true;
              filterAndLoad.loadPage = `${+currentPage + 1}`;
              setFilterAndLoad({
                ...filterAndLoad,
                loadPage: `${+currentPage + 1}`,
              });
            }}
          />
        </div>
      )}
    </StructuresStyled>
  );
};

export default StructuresPage;
